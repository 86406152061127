<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$t('Map')"
        :items="[]"
        :items-context="workFlosAddressesContext"
        :available-filters="$store.getters['workFlo/getAvailableMapFilters']"
        :payload.sync="payload"
        :module="module"
        :allow-add="false"
    >

    </base-template>

    <b-row>
      <b-col md="6">
        <field-address-by-query
            name="place"
            :model.sync="currentAddress"
            :displayComplement="false"
            :disabled="false"
        />
      </b-col>
      <b-col md="6">
        <b-form-group
            :label="capitalize($t('radius'))"
            label-for="v-first-name"
        >
          <vue-slider
              id="v-first-name"
              v-model="currentKilometers"
              :adsorb="true"
              :interval="20"
              :marks="true"
              class="mx-1"
              :min="0"
              :max="200"
              direction="ltr"
          />
        </b-form-group>

      </b-col>
    </b-row>

    <l-map
        style="height: 600px"
        class="mt-2"
        :zoom="map.zoom"
        :center="map.center"
        ref="mapRef"
    >
      <l-tile-layer
          :url="map.url"
          :attribution="map.attribution"
      ></l-tile-layer>
      <v-marker-cluster v-if="displayByGroups">
        <work-flo-map-marker
            v-for="marker in markers"
            :display-by-groups="displayByGroups"
            :marker="marker"
        />
      </v-marker-cluster>
      <work-flo-map-marker
          v-else
          v-for="marker in markers"
          :display-by-groups="displayByGroups"
          :marker="marker"
      />

      <l-circle
          v-if="currentAddress != ''"
          :lat-lng="[currentAddress.latitude, currentAddress.longitude]"
          :radius="1000*currentKilometers"
          fill-color="#f03"
          :opacity="0.2"
      />

      <l-geo-json
          :geojson="departements"
          :options="geoJsonOptions"
          :options-style="geoJsonStyleFunction"
      >
        <l-tooltip>Hello!</l-tooltip>
      </l-geo-json>
    </l-map>
  </div>
</template>

<script>
import { ref, computed, watch }                from '@vue/composition-api'
import { capitalize }                          from '../../../utils/filter'
import { Icon }                                from 'leaflet'
import { LMap, LTileLayer, LCircle, LGeoJson } from 'vue2-leaflet'
import store                                   from '../../../store'
import useAPI                                  from '../../../utils/useAPI'
import BaseTemplate                            from '../../../components/base3/Base.vue'
import FieldAddressByQuery                     from '../../../components/input/AddressAutosuggest.vue'
import Vue2LeafletMarkerCluster                from 'vue2-leaflet-markercluster'
import VueSlider                               from 'vue-slider-component'
import WorkFloMapMarker                        from './components/WorkFloMapMarker.vue'
import departements                            from './data/departements.json'
import { clone }                               from '../../../utils/utils'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const usersColorDepartments = [
  {
    user: {
      id: 870,
      _display: 'Elsa MARTIN'
    },
    color: '#ff0000',
    // departments: ['01', '03', '12', '15', '16', '17', '18', '19', '21', '23', '24', '25', '28', '31', '36', '37', '39', '41', '42', '43', '45', '46', '47', '48', '58', '63', '67', '68', '69', '70', '71', '73', '74', '79', '81', '82', '85', '86', '87', '88', '89', '90']
    departments: ['03',
      '15',
      '43',
      '21',
      '25',
      '70',
      '39',
      '58',
      '71',
      '90',
      '89',
      '18',
      '28',
      '36',
      '37',
      '45',
      '41',
      '91',
      '92',
      '95',
      '78',
      '19',
      '23',
      '87',
      '16',
      '17',
      '79',
      '86',
      '44',
      '49',
      '53',
      '72',
      '85',
      '12',
      '32',
      '46',
      '82',
      '63',]
  },
  {
    user: {
      id: 1285,
      _display: 'Léo Cicchi'
    },
    color: '#00ff00',
    // departments: ['04', '05', '06', '07', '11', '13', '14', '2A', '2B', '26', '27', '30', '34', '38', '48', '50', '53', '59', '69', '60', '61', '62', '66', '76', '80', '83', '84']
    departments: ['01',
      '07',
      '26',
      '74',
      '38',
      '42',
      '69',
      '73',
      '2A',
      '2B',
      '67',
      '68',
      '54',
      '55',
      '57',
      '88',
      '08',
      '10',
      '52',
      '51',
      '02',
      '59',
      '60',
      '62',
      '80',
      '11',
      '30',
      '34',
      '48',
      '66',
      '04',
      '06',
      '13',
      '05',
      '83',
      '84',]
  },
  {
    user: {
      id: 7,
      _display: 'Antoine RIGOLLET'
    },
    color: '#0000ff',
    // departments: ['02', '08', '09', '10', '22', '29', '32', '33', '35', '40', '44', '49', '51', '52', '54', '55', '56', '57', '64', '65', '72', '75', '77', '78', '91', '92', '93', '94', '95']
    departments: ['22',
      '29',
      '35',
      '56',
      '75',
      '77',
      '93',
      '94',
      '14',
      '27',
      '50',
      '61',
      '76',
      '24',
      '33',
      '40',
      '47',
      '64',
      '09',
      '31',
      '65',
      '81',]
  },
]

export default {
  components: {
    FieldAddressByQuery,
    WorkFloMapMarker,
    BaseTemplate,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    LMap,
    LTileLayer,
    LCircle,
    LGeoJson,
    VueSlider
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const module = ref('workFlosMap')
    const payload = ref({})
    const isReady = ref(false)
    const displayByGroups = ref(true)
    const mapRef = ref()
    const map = ref({
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 6,
      center: [47, 1.011],
    })
    const markers = ref([])
    const currentAddress = ref('')
    const currentKilometers = ref(100)
    const isAutocompleteLoaded = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { workFlosAddressesContext } = useAPI()

    const geoJsonOptions = computed(() => {
      return {
        onEachFeature: onEachFeature()
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      fetchWorkFlosAddresses(val)
          .then(response => {
            if (!isAutocompleteLoaded.value) {
              loadInit()
            }
            markers.value = []

            populateMarkers(response.data.results)
          })
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchWorkFlosAddresses, fetchAutocompleteWorkFloStatuss, fetchAutocompleteEmployees } = useAPI()

    const loadInit = () => {
      fetchAutocompleteWorkFloStatuss()
      fetchAutocompleteEmployees()

      isAutocompleteLoaded.value = true
    }

    const populateMarkers = (workFlos) => {
      workFlos.forEach(workFlo => {
        workFlo.company.addresses.forEach(address => {
          if (displayByGroups.value) {
            markers.value.push(
                {
                  latLon: [address.latitude, address.longitude],
                  address: address,
                  company: workFlo.company,
                  workFlo: workFlo
                }
            )
          } else {
            markers.value.push(
                {
                  latLon: [address.latitude, address.longitude],
                  address: address,
                  company: workFlo.company,
                  workFlo: workFlo
                }
            )
          }
        })

      })
    }

    const geoJsonStyleFunction = department => {
      if (getUserDepartment(department.properties.code) != null) {
        return {
          color: getUserDepartment(department.properties.code).color,
          weight: 1,
          opacity: 0,
        }
      } else {
        return {
          color: '#000000',
          weight: 1,
          opacity: 0
        }
      }

    }

    const getUserDepartment = department => {
      if (usersColorDepartments[0].departments.find(d => d == department) !== undefined) {
        return usersColorDepartments[0]
      } else if (usersColorDepartments[1].departments.find(d => d == department) !== undefined) {
        return usersColorDepartments[1]
      } else if (usersColorDepartments[2].departments.find(d => d == department) !== undefined) {
        return usersColorDepartments[2]
      } else {
        return null
      }
    }

    const onEachFeature = () => {
      return (feature, layer) => {
        let userDepartment = getUserDepartment(feature.properties.code)

        if (userDepartment != null) {
          layer.bindTooltip(
              '<div><b>' +
              userDepartment.user._display +
              '</b> <i>(' + feature.properties.nom + ' ' + feature.properties.code +
              ')</i></div>',
              { permanent: false, sticky: true }
          )
        } else {
          layer.bindTooltip(
              '<div><b>-</b> <i>(' + feature.properties.nom + ' ' + feature.properties.code +
              ')</i></div>',
              { permanent: false, sticky: true }
          )
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    store.dispatch('moduleView/getModuleViews', {
      per_page: 5000
    })
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)
          console.log(clone(currentView))

          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            page: 1,
            per_page: 5000,
            sorts: currentView.sorts,
          }
        })

    return {
      // Components
      capitalize,

      // Data
      module,
      isReady,
      payload,
      mapRef,
      map,
      markers,
      displayByGroups,
      currentAddress,
      currentKilometers,
      departements,
      geoJsonOptions,

      // Computed
      workFlosAddressesContext,

      // Methods
      geoJsonStyleFunction,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>
@import '../../../../node_modules/leaflet/dist/leaflet.css';

@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

</style>